export const easterEgg = () => {
    console.log(
        `%c................................................................................
................................................................................
................................................................................
................................................................................
.................................    ......    .................................
........................... ...,:clddxxxxxxddlc:,... ...........................
..........................,cdOKNWWWNXXKKKKXXNWWWNKOdc,..........................
.......................:d0NWNKkdl:;,'......',;:ldkKNWN0d:.......................
....................,d0WWXkl;..  ..............  ..;lkXWW0d,....................
..................;xXWNkc'. ........................ .'ckNWXx;. ................
................'dXWXx;. .............................. .;xXWXd'................
...............:0WNk;.....      ..................        .;kNW0:...............
..............oXWXl......'cllllc,................'cllllllc'..lXWXo..............
........... .oNWK:.....'l0WWWWWWX0000000000000000XWWWWMW0c....:KWNo. ...........
............lNMK:.... .xWWWWWWWWWWWWWWWWWWWWWWWWWWWWWN0c.......:KMNl............
.......... ;KMXc..... .OMWWWWWWWWWWWWWWWWWWWWWWWWWWN0c..........cXMK; ..........
......... .dWWk. .... .kWWWWWWWNKKKKKKKKKKKKKKKKKKOc........... .kWWd. .........
......... ,0MXc........;0WWWWWWO,.,,,,,,,,,,,....................cXM0, .........
......... :XM0, ...... .kMWWWWWO..'oOKKKKKKK0x,................. ,0MX: .........
..........lNMO' ...... .kMWWWWMO.  .:kNWWWWWWWXx,. ............. 'OMNl..........
..........cNM0' ...... .kWWWWWMO' ....:kXNWWWWWWXd'............. '0MNc..........
......... ;XMK; ...... .kWWWWWWO' ......':kNWWWWWNc ............ ;KMX; .........
......... .OMWo........:KWWWWWWO' ....... .:kNWWWNc............ .oWMO. .........
...........lNM0, .... .OMWWWWWMO. ...........:kNMNc ........... ,0MNl...........
.......... .kWWx. ... 'OWWWWWWMO. .............:OXc .......... .xWWk. ..........
........... ,0WNd.... .xWWWWWWMO. ...............;'............dNW0, ...........
.............;0WNx'.....c0WWWWMO. ...........................'xNW0;.............
..............,kWW0:......c0NWMO. ..........................:0WWk,..............
.............. .oXWNx;. ....c0WO. ...................... .;xNWXo. ..............
.................,xXWXx:. ....ll. ......................:xXWXx,.................
...................;xXWN0o;.. .................... ..;o0NWXx;...................
.....................'lkXWNKxl:,...         ....,:lxKNWXOl'.....................
........................,cx0NWWXKOxdoolllloodxOKXWWN0xc,........................
............................,:oxk0KXNNWWWWNNXK0kxo:,............................
.............................  ....',;;;;;;,'....  .............................
................................................................................
................................................................................
................................................................................
................................................................................
..............................................................................:)`,
        "font-family:monospace"
    )
}
